
.projects {
    /* min-height: 800px; */
    height: 100vh;
    width: 100vw;
    /* border: 2px solid rgb(255, 245, 245); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 3%;
}

.swiper {
    width: 90%;
    /* padding: 20%; */
    /* border: 10px solid orange; */
    min-height: 600px;
    height: 75%;
    /* height: auto; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.slide {
    /* border: 3px solid red; */
    width: 110%;
    /* padding: 3%; */
    /* border: 1px solid white; */
    /* height: 98% !important; */
    /* min-height: 600px; */
    height: auto;
}

.slideContent {
    /* border: 2px solid yellow; */
    width: 100%;
    height: 80%;
    min-height: 600px;
    display: flex;
}

.slideHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 10%;
    font-family: 'Play', sans-serif;
}

.swipetext {
    position: absolute;
    /* top: 50%; */
    right: 20px;
    /* border-bottom: 1px solid rgb(12, 175, 250); */
}

/* .buttonDiv{
    height: 10%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
} */

.down {
    color: white;
    font-size: 1.7rem;
}

.downText {
    color: white;
    font-size: 1.5rem;
    margin: 0;
}

.down:hover {
    cursor: pointer;
}

.cellars, .fureverWalks, .gameBnb, .portfolio {
    width: 100%;
    height: 100% !important; 
}

/* GameBnb */
.projectLink {
    margin: 0;
    color: white !important;
    font-size: 2em;
    padding:10px;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.projectLink:visited, .projectLink:link, .projectLink:active {
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.projectLink:hover {
    border-bottom: 1px solid white;
}

.github {
    margin: 10px;
    /* padding: 10px; */
    color: white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.github:visited, .github:link, .github:active {
    text-decoration: none;
    color: white;
}

.github:hover {
    border-bottom: 1px solid white;
}

.githubTag {
    font-size: 4rem;
}

.githubIcon {
    font-size: 2rem;
}

.slideLeft {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
}

.slideRight {
    width: 50%;
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
    /* padding-bottom: 5%; */
    /* border: 2px solid olivedrab; */
}

.rightCard {
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid white; */
}

.descriptionCard {
    width: 80%;
    height: auto;
    /* padding-top: 5%; */
    /* border: 2px solid white; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Play', sans-serif;
    /* font-weight: 400; */
}

.descriptionDiv {
    border-left: 4px solid orange;
    border-right: 4px solid orange;
    /* box-shadow: 0px 1px 5px 1px orange; */
    border-radius: 10px;
    height: 40%;
    margin-bottom: 10%;
}

.description {
    font-size: 1.1em;
    padding: 0px 15px;
    margin: 0px;
    height: auto;
    /* padding-top: 5%; */
    margin-top: 15%;
    position: relative;
    opacity: 0;
}

.technologiesDiv {
    width: 70%;
    min-width: 300px;
    height: auto;
    max-height: 30%;
    /* border: 2px solid white; */
    border-left: 4px solid yellow;
    border-right: 4px solid yellow;
    border-radius: 10px;
    /* box-shadow: 0px 0px 10px 3px yellow; */
    /* border-radius: 5%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.description:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.technologiesDiv:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
}

.listDiv {
    display: flex;
    justify-content: flex-start;
    opacity: 0;
    position: relative;
    padding-top: 12%;
}

/* .listDiv ul {
    opacity: 0;
} */

/* .listDiv ul li {
    opacity: 0;
} */

.backend, .frontend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* opacity: 0; */
}

.video {
    margin-bottom: 5%;
    max-width: 95%;
    height: auto;
    /* box-shadow: 0px 0px 10px 3px rgb(12, 175, 250); */
}

.downpg1 {
    /* color: white;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none; */
    position: absolute;
    z-index: 1;
    /* bottom: 60px; */
    left: 50%;
    bottom: 100px;
    text-decoration: none;
}

.downpg1:after {
    color: white;
    text-decoration: none;
}

.arrow {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 10%;
    left: 50%;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid orange;
    border-right: 3px solid yellow;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll1 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -.4s;
}

@keyframes scroll1{
    0%{
        opacity: 1;
        transform: rotate(45deg) translate(-15px, -15px);
        border-bottom: 3px solid orange;
        border-right: 3px solid yellow;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}


/* .swiperContainer {
    width: 100%;
    height: 120%;
}

.cubeSlide {
    height: 30%;
    width: 60%;
    max-width: 200px;
    max-height: 300px;
}

.cubeSlideContent {
    background-color: white;
    height: 200px;
    width: 200px;
    color: black;

} */