.body {
    height: 100vh;
    font-family: 'Poppins';
    color: white;
}

.canvas {
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    width: 100vw;
    height: 100vh;
}
