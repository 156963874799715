
.contactMe {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding-top: 5%;
    font-family: 'Play', sans-serif;
}

.upArrow {
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    font-size: 3rem;
    text-decoration: none;
}

.icon:hover{
    /* color: rgb(253, 9, 253); */
    color: rgb(0, 211, 105);
}

.upText:hover {
    /* color: rgb(253, 9, 253); */
    color: rgb(0, 211, 105);
}

.upArrow:after {
    color: white;
    text-decoration: none;
}

.upText {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.header {
    font-size: 2.5rem;
    margin-bottom: 60px;
    margin-top: 7%;
    text-shadow: 1px 2px 2px black;
    z-index: 3;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 50%;
    border-radius: 30%;
    box-shadow: 0px 0px 3px 3px rgb(12, 175, 250);
    /* box-shadow: 0px 0px 5px 5px rgb(255, 210, 9); */
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 90%;
    font-size: 1.3rem;
    border-radius: 50px;
    box-shadow: 0px 0px 3px 3px rgb(0, 211, 105);
}

.form:hover {
    box-shadow: 0px 0px 5px 5px rgb(0, 211, 105);
}

.card:hover {
    box-shadow: 0px 0px 5px 5px rgb(12, 175, 250);
}

.form div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 60%;
}

.form label {
    margin: 5px;
}

.form input, .form textarea {
    border: none;
    box-shadow: 0px 0px 2px 2px rgb(0, 0, 0);
}

.form input:hover, .form textarea:hover {
    /* border-radius: 25%; */
    /* border-radius: 50px; */
    box-shadow: 0px 0px 2px 2px rgb(0, 211, 105);

}

.nameInput {
    width: 50%;
    font-family: 'Play', sans-serif;
    font-size: 1.2rem;
}

.emailInput {
    width: 60%;
    font-family: 'Play', sans-serif;
    font-size: 1.2rem;
    /* border: none; */
}

.textDiv {
    height: 30%;
}

.textInput {
    width: 115%;
    height: 70%;
    font-family: 'Play', sans-serif;
    font-size: 1.2rem;
}

.submitBtn {
    height: 55px;
    width: 75px;
    border: 0;
    background-color: rgba(255, 255, 255, 0);
}

.mailBtn {
    width: 75%;
    height: 75%;
    /* border-radius: 50%; */
    /* background-color: rgb(12, 175, 250); */
    color: white;
    /* font-size: 1rem; */
    cursor: pointer;
    /* box-shadow: 0px 0px 3px 3px rgb(253, 9, 253) */
}

.mailBtn:hover {
    /* color: rgb(255, 230, 7); */
    color: rgb(12, 175, 250);
}

