html {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    font-family: 'Roboto', sans-serif;
}

body {
 height: 100%;
 width: 100%;
 /* background-color: rgba(0, 32, 90, 0.288); */
 background-color: rgba(2, 0, 141, 0.089);
 background-image: url('/src/assets/space3.jpg');
 background-size: cover;
 background-repeat: round;
 /* background-clip: border-box; */
 background-blend-mode: difference;
 /* background-origin: border-box; */
 /* background-position: center; */
 /* overflow-x: hidden; */
 /* overflow-y: scroll; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 25%;
    width: 50%;
    min-width: 500px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.342);
    background-color: rgba(21, 35, 61, 0.384);
}

.navList {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: 'Play', sans-serif;
}

.navA {
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
}

.navA:hover {
    /* color: yellow; */
    color: rgb(3, 233, 118);
}

.rightSide {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 79%;
    left: 62%;
    /* border: 2px solid white; */
    height: 50%;
    width: 50%;
    min-height: 200px;
    padding-top: 2%;
}

#socials {
    /* position: absolute;
    z-index: 3;
    top: 53%;
    left: 72%; */
    width: 100%;
    height: 50%;
    margin:0px;
    padding:0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-top: 7%; */
}

#socialsList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 40%;
    height: 100%;
    /* padding: 0px; */
    margin: 0px;
    padding: 0px 12% 0px 0px;
    /* padding-top: 7%; */
    list-style: none;
    /* position: absolute;
    z-index: 3;
    top: 100%;
    left: 72%; */
    /* font-size: rem; */
}

#socialsList:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* border-bottom: 1px solid white; */
    transform: scaleX(1);
}


.anchorT {
    text-decoration: none;
    font-size: 1.8rem;
    color: white;
    position: absolute;
    font-weight: bold;
    text-shadow: 3px 6px 10px black;
    z-index: 3;
}

.socialIcons {
    width: 1.8rem;
    height: 1.8rem;
}

.resume {
    font-size: 1.2rem;
    /* margin-bottom: 5%; */
    /* position: absolute; */
    z-index: 2;
    margin: 0;
    padding: 5px 7px 0px 0px;
}

.resume:hover {
    cursor: hand;
    /* color: orange; */
    /* border-bottom: 1px solid white; */
    border-radius: 5%;
    font-size: 1.3rem;
}

.socialIcons:hover {
    cursor: hand;
    /* border-bottom: 1px solid white; */
    border-radius: 5%;
    height: 2rem;
    width: 2rem;
}

/* .star {
    position: absolute;
    top: 10%;
    left: 10%;
    z-index: 1 !important;
} */

.anchorT:visited {
    color: white;
    text-decoration: none;
}

.anchorT::after {
    color: white;
}

#container {
    position: absolute;
    z-index: 1;
    width: 100%;
    /* border: 2px solid white; */
    /* height: 100vh; */
    /* height: 100%; */
    top: 36%;
    display: grid;
    place-content: center;
    font-family: 'Play', sans-serif;
    text-shadow: 5px 5px 10px black;
}

#content {
    display: flex;
    gap: 12em;
    width: 100%;
    /* padding-top: 3em; */
    position: absolute;
    right: 8%;
    /* border: 2px solid red; */
}

#content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* border-bottom: 1px solid white; */
    transform: scaleX(1);
}

#s1, #s2, #s3 {
    flex-basis: 0;
    flex-grow: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)
}

.name, .role {
    flex-basis: 0;
    flex-grow: 1;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0)
}

.name {
    position: absolute;
    z-index: 2;
    top: 13%;
    /* left: 12.5%; */
    font-size: 4rem;
    /* width: 55vh; */
    line-height: 180%;
    padding-top: 30px;
    /* text-align: right; */
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    font-weight: bold;
    /* color: white; */
    text-shadow: 0 0 10px black;
    /* border: 1px solid white; */
    width: 58%;
}

.s {
    padding-left: 10px;
}

/* .break {
    width: 10px;
} */

.role {
    /* position: absolute;
    z-index: 2;
    top: 13%;
    left: 68.5%; */
    font-size: 2.1rem;
    /* width: 55vh; */
    /* padding: 15px 64px 20px 0px; */
    /* text-align: right; */
    /* overflow: visible; */
    height: 30%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border:1px solid red; */
    margin: 0px;
    padding-right: 2%;
}

.downpg1 {
    position: absolute;
    z-index: 1;
    bottom: 60px;
    left: 50%;
    text-decoration: none;
}

.downpg1:after {
    color: white;
    text-decoration: none;
}

.downText {
    /* margin-bottom: 10%; */
    z-index: 2;
}

.arrow1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 30%;
    /* position: relative; */
}

.arrow {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 10%;
    left: 50%;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid orange;
    border-right: 3px solid yellow;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll1 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -.4s;
}

@keyframes scroll1{
    0%{
        opacity: 1;
        transform: rotate(45deg) translate(-15px, -15px);
        border-bottom: 3px solid orange;
        border-right: 3px solid yellow;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}


.word {
	font-family: 'Play', sans-serif;
	perspective: 1000px; 
    /* height: 200%; */
}

.word span {
	cursor: pointer;
	display: inline-block;
	font-size: 65px;
	user-select: none;
	line-height: .8;
}

.word span:nth-child(1).active {
	animation: balance 1.5s ease-out;
	transform-origin: bottom left;
}

.word span:nth-child(5).active {
	animation: balance 1.5s ease-out;
	transform-origin: bottom left;
}

@keyframes balance {
	0%, 100% {
		transform: rotate(0deg);
	}
	
	30%, 60% {
		transform: rotate(-45deg);
	}
}

.word span:nth-child(2).active {
	animation: shrinkjump 1s ease-in-out;
	transform-origin: bottom center;
}

.word span:nth-child(6).active {
	animation: shrinkjump 1s ease-in-out;
	transform-origin: bottom center;
}

@keyframes shrinkjump {
	10%, 35% {
		transform: scale(2, .2) translate(0, 0);
	}
	
	45%, 50% {
		transform: scale(1) translate(0, -150px);
	}
	
	80% {
		transform: scale(1) translate(0, 0);
	}
}

.word span:nth-child(3).active {
	animation: falling 2s ease-out;
	transform-origin: bottom center;
}

.word span:nth-child(7).active {
	animation: falling 2s ease-out;
	transform-origin: bottom center;
}

@keyframes falling {
	12% {
		transform: rotateX(240deg);
	}
	
	24% {
		transform: rotateX(150deg);
	}
	
	36% {
		transform: rotateX(200deg);
	}
	
	48% {
		transform: rotateX(175deg);
	}
	
	60%, 85% {
		transform: rotateX(180deg);
	}
	
	100% {
		transform: rotateX(0deg);
	}
}

.word span:nth-child(4).active {
	animation: rotate 1s ease-out;
}

.word span:nth-child(8).active {
	animation: rotate 1s ease-out;
}

@keyframes rotate {
	20%, 80% {
		transform: rotateY(180deg);
	}
	
	100% {
		transform: rotateY(360deg);
	}
}

/* .word span:nth-child(5).active {
	animation: toplong 1.5s linear;
}

@keyframes toplong {
	10%, 40% {
		transform: translateY(-48vh) scaleY(1);
	}
	
	90% {
		transform: translateY(-48vh) scaleY(4);
	}
} */