
.aboutMe {
    /* min-height: 600px; */
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid white; */
    /* font-family: 'Roboto', sans-serif; */
    position: relative;
    font-family: 'Play', sans-serif;
}

.aboutMe__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    height: 90%;
    /* border: 2px solid grey; */
}

.infoDiv {
    width: 50%;
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card {
    width: 85%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid rgb(201, 201, 201); */
    /* box-shadow: 0px 0px 5px 5px #ff1ff4; */
    padding: 40px;
    margin-left: 20px;
    /* background-color: rgba(0, 0, 0, 0.438); */
}

.description {
    font-size: 1.2rem;
    margin-bottom: 60px;
}

.pictureDiv {
    width: 50%;
    height: 100%;
    /* min-height: 500px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid rgb(255, 255, 255); */
    /* border: none; */
    transform: translate(0%, 0%);
}

.image {
    height: auto;
    width: 65%;
    min-width: 300px;
    min-height: 300px;
    border-radius: 50%;
    /* border: 1px solid rgb(0, 0, 0); */
    box-shadow: 0px 0px 10px 3px #ffffffad;
    transition: opacity 0.5s ease-in-out;
}

.buttonDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}

.down {
    color: white;
    font-size: 1.7rem;
}

.downText {
    color: white;
    font-size: 1.5rem;
    margin: 0;
}

.down:hover {
    cursor: pointer;
}

.arrow1 {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    color: white;
}

.arrow1::after {
    color: white;
    text-decoration: none;
}

.arrow {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 10%;
    left: 50%;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid orange;
    border-right: 3px solid yellow;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll1 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -.4s;
}

@keyframes scroll1{
    0%{
        opacity: 1;
        transform: rotate(45deg) translate(-15px, -15px);
        border-bottom: 3px solid orange;
        border-right: 3px solid yellow;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}