

.technologies {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.icon {
    /* height: 35px;
    width: 35px;
    margin: 14px; */
    /* height: 1.7em;
    width: 1.7em;
    margin: 0.5em; */
    height: auto;
    width: 15%;
    margin: 0.5em;
}

.header {
    margin-bottom: 50px;
    text-shadow: 2px 3px 4px black;
    font-family: 'Play', sans-serif;
}

.card {
    height: auto;
    /* width: auto; */
    width: 70%;
    /* min-width: 400px; */
    display: flex;
    /* justify-content: space-around; */
    justify-content: space-around;
    /* background-color: rgba(248, 217, 217, 0.548);
    color: rgb(255, 255, 255);
    border-radius: 15px; */
    text-shadow: 1px 1px 1px black;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Play', sans-serif;
    font-size: 1.2rem;
    margin-bottom: 5%;
    /* border: 2px solid white; */
    /* box-shadow: 0px 0px 5px 5px rgb(65, 217, 255); */
}

.column {
    width: 25%;
}

.card ul {
    height: 100%;
    width: 100%;
    min-width: 200px;
    margin: 0;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.651);
    backdrop-filter: blur(80px);
    color: rgb(255, 255, 255);
    border: 1px solid orange;
    border-radius: 15px;
    padding-inline-start: 0px;
}

.ul1:hover {
    box-shadow: 0px 0px 5px 2px yellow;
}

.ul2:hover {
    box-shadow: 0px 0px 5px 2px orange;
}

.ul3:hover {
    box-shadow: 0px 0px 5px 2px red;
}

/* .card li {
    list-style: none;
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    padding-left: 40%;
} */

.liItem {
    list-style: none;
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
    padding-left: 40%;
    opacity: 0;
    position: relative;
}

.liItem:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0;
}

.buttonDiv {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100px;
}


.down {
    color: white;
    font-size: 1.7rem;
}

.downText {
    color: white;
    font-size: 1.5rem;
    margin: 0;
}

.down:hover {
    cursor: pointer;
}

.arrow3 {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.arrow3::after {
    color: white;
    text-decoration: none;
}

.arrow {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 10%;
    left: 50%;
}

.arrow span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 3px solid orange;
    border-right: 3px solid yellow;
    transform: rotate(45deg);
    margin: -10px;
    animation: scroll1 2s infinite;
}

.arrow span:nth-child(2) {
    animation-delay: -.2s;
}

.arrow span:nth-child(3) {
    animation-delay: -.4s;
}

@keyframes scroll1{
    0%{
        opacity: 1;
        transform: rotate(45deg) translate(-15px, -15px);
        border-bottom: 3px solid orange;
        border-right: 3px solid yellow;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}